export default ({ IDL }) => {
  const frozeNFT = IDL.Record({
    'alias' : IDL.Opt(IDL.Text),
    'owner' : IDL.Principal,
    'timestamp' : IDL.Int,
  });
  const AlphaDeck = IDL.Service({
    'claimNFT' : IDL.Func([IDL.Principal], [], []),
    'confirmLockForProduction' : IDL.Func([], [IDL.Text], []),
    'getPrincipalNFT' : IDL.Func(
        [IDL.Principal],
        [IDL.Opt(frozeNFT)],
        ['query'],
      ),
    'listNFT' : IDL.Func([], [IDL.Vec(frozeNFT)], ['query']),
    'lockForProduction' : IDL.Func([], [IDL.Text], []),
    'ping' : IDL.Func([], [IDL.Text], ['query']),
    'serveCard' : IDL.Func([IDL.Nat], [IDL.Opt(IDL.Text)], ['query']),
    'supplyTotal' : IDL.Func([], [IDL.Nat], ['query']),
    'uploadAsset' : IDL.Func([IDL.Nat, IDL.Text], [], ['oneway']),
    'validateAssets' : IDL.Func([], [], ['query']),
  });
  return AlphaDeck;
};
export const init = ({ IDL }) => { return []; };