export default ({ IDL }) => {
  const TarotCardSuits = IDL.Text;
  const TarotCard = IDL.Record({
    'name' : IDL.Text,
    'suit' : TarotCardSuits,
    'number' : IDL.Nat,
    'index' : IDL.Nat,
  });
  const Timestamp = IDL.Int;
  const TarotCardData1 = IDL.Record({
    'element' : IDL.Text,
    'meanings' : IDL.Record({
      'shadow' : IDL.Vec(IDL.Text),
      'light' : IDL.Vec(IDL.Text),
    }),
    'affirmation' : IDL.Text,
    'astrology' : IDL.Text,
    'mythology' : IDL.Text,
    'fortunes' : IDL.Vec(IDL.Text),
    'keywords' : IDL.Vec(IDL.Text),
    'archetype' : IDL.Text,
    'numerology' : IDL.Text,
    'arcana' : IDL.Text,
    'questions' : IDL.Vec(IDL.Text),
    'index' : IDL.Nat,
    'hebrew' : IDL.Text,
  });
  const TarotCardData2 = IDL.Record({
    'meaningLoveReversed' : IDL.Text,
    'keywordsLoveReversed' : IDL.Vec(IDL.Text),
    'meaningLoveUpright' : IDL.Text,
    'keywordsGeneralReversed' : IDL.Vec(IDL.Text),
    'meaningCareerReversed' : IDL.Text,
    'description' : IDL.Text,
    'meaningCareerUpright' : IDL.Text,
    'keywordsCareerReversed' : IDL.Vec(IDL.Text),
    'meaningReversed' : IDL.Text,
    'keywordsGeneralUpright' : IDL.Vec(IDL.Text),
    'meaningUpright' : IDL.Text,
    'index' : IDL.Nat,
    'keywordsCareerUpright' : IDL.Vec(IDL.Text),
    'keywordsLoveUpright' : IDL.Vec(IDL.Text),
  });
  const CardDraw = IDL.Record({
    'theme' : IDL.Text,
    'cardIndex' : IDL.Nat,
    'principal' : IDL.Principal,
    'card' : TarotCard,
    'reversed' : IDL.Bool,
    'timestamp' : Timestamp,
    'data1' : TarotCardData1,
    'data2' : TarotCardData2,
  });
  const Err = IDL.Variant({
    'permissionDenied' : IDL.Null,
    'invalidId' : IDL.Null,
  });
  const Result = IDL.Variant({ 'ok' : CardDraw, 'err' : Err });
  const Res = Result;
  const Id = IDL.Nat;
  const NextAvailableDraw = IDL.Record({
    'now' : Timestamp,
    'theme' : IDL.Text,
    'principal' : IDL.Principal,
    'interval' : Timestamp,
    'lastDraw' : IDL.Opt(Timestamp),
    'nextDraw' : Timestamp,
  });
  return IDL.Service({
    'countDraws' : IDL.Func([], [IDL.Nat], ['query']),
    'createDailyDraw' : IDL.Func([IDL.Text, IDL.Text], [CardDraw], []),
    'getCardDraw' : IDL.Func([IDL.Nat], [Res], []),
    'getExistingDraw' : IDL.Func(
        [IDL.Text, IDL.Text],
        [IDL.Opt(IDL.Tuple(Id, CardDraw))],
        [],
      ),
    'importTarotCardData1' : IDL.Func([IDL.Vec(TarotCardData1)], [], []),
    'importTarotCardData2' : IDL.Func([IDL.Vec(TarotCardData2)], [], []),
    'importTarotCards' : IDL.Func([IDL.Vec(TarotCard)], [], []),
    'listPrincipleDailyDraws' : IDL.Func([IDL.Text], [IDL.Vec(CardDraw)], []),
    'listTarotCardData1' : IDL.Func([], [IDL.Vec(IDL.Opt(TarotCardData1))], []),
    'listTarotCardData2' : IDL.Func([], [IDL.Vec(IDL.Opt(TarotCardData2))], []),
    'listTarotCards' : IDL.Func([], [IDL.Vec(IDL.Opt(TarotCard))], []),
    'nextDrawTime' : IDL.Func([IDL.Text, IDL.Text], [NextAvailableDraw], []),
  });
};
export const init = ({ IDL }) => { return []; };